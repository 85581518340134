<template>
  <div>
    <b-card class="mt-3">
      <b-card-header>
        <b-card-title>
          <h2 class="brand-text mb-0">
            Użytkownik: {{ business.name }}
          </h2>
        </b-card-title>
      </b-card-header>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2 class="brand-text mb-0">
            Dane konta
          </h2>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        Email: {{ business.email }}
        <br>
        Miasto: {{ business.city }}
        <br>
        Typ konta: {{ business.account_type }}
        <br>
        Kraj: {{ business.country }}
        <br>
        założono: {{ business.created_at }}
      </b-card-body>
    </b-card>

    <b-card>
      <div>
        <h2>
          Notatki
        </h2>
        <div
          v-for="note in notes"
          :key="note"
        >
          <div class="d-flex justify-content-between mt-1">
            <div>
              {{ note.note }}
            </div>

            <div>
              <button
                class="btn btn-danger"
                style="padding: 5px;"
                @click="DeleteNote(note.id)"
              >
                Usuń notatkę
              </button>
            </div>
          </div>
          <hr>
        </div>

        <button
          v-b-modal.modal-1
          class="btn btn-primary"
          variant="primary"
        >
          Dodaj notatkę
        </button>

        <b-modal
          id="modal-1"
          title="Dodaj notatkę"
        >
          <h2>
            Dodaj notatkę
          </h2>
          <b-card-body>
            <form @submit.prevent="AddNote()">
              <input
                v-model="note"
                class="form-control"
              >
            </form>
          </b-card-body>
        </b-modal>
      </div>
    </b-card>

    <b-card>

      <h2>
        Wyślij wiadomość
      </h2>

      <div class="form-group">
        <label for="title">Tytuł: </label>
        <textarea
          id="title"
          v-model="message.content"
          class="form-control"
        />
      </div>

      <button
        class="btn btn-primary"
        @click="sendMessage()"
      >
        Wyślij
      </button>
    </b-card>

    <b-card>
      <h2>
        Zamówienia
      </h2>
      <div
        v-for="order in orders"
        :key="order"
      >
        <div class="d-flex justify-content-between mt-1">
          {{ order.status }} | {{ order.created_at }} | {{ order.updated_at }}
        </div>
        <hr>
      </div>
    </b-card>

    <b-card>
      <router-link to="/Widok-pracownika">
        <button
          class="btn btn-primary"
          style="width:100%"
        >
          Powrót
        </button>
      </router-link>
    </b-card>

    <div style="padding-top: 20px" />
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'
import { BCard, BCardText, BImg } from 'bootstrap-vue'

export default {
  name: 'OfferAdd',
  components: {
    BCard,
    BCardText,
    BImg,
  },
  data() {
    return {
      offer: {},
      categories: [],
      errors: [],
      business: {},
      note: '',
      notes: [],
      orders: [],
      message: {},
    }
  },
  mounted() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}Profile/${this.$route.params.id}`, config)
      .then(response => {
        this.business = response.data.success
        this.orders = response.data.orders
      })
      .catch(error => {
        swal.fire({
          title: 'Błąd',
          icon: 'error',
          text: 'Nie udało się pobrać danych',
          type: 'error',
          confirmButtonText: 'Ok',
        })
      })

    this.FetchNotes()
  },
  methods: {
    AddNote() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const postdata = {
        note: this.note,
      }

      axios.post(`${process.env.VUE_APP_API_URL}Client/Note/${this.$route.params.id}`, postdata, config)
        .then(response => {
          swal.fire({
            title: 'Udało się!',
            text: 'Notatka została dodana',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.FetchNotes()
        })
        .catch(error => {
          swal({
            title: 'Błąd!',
            text: 'Nie udało się dodać notatki',
            type: 'error',
          })
        })
    },
    FetchNotes() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Client/Note/${this.$route.params.id}`, config)
        .then(response => {
          this.notes = response.data.notes
        })
        .catch(error => {
          swal({
            title: 'Błąd!',
            text: 'Nie udało się pobrać notatek',
            type: 'error',
          })
        })
    },
    DeleteNote(id) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.delete(`${process.env.VUE_APP_API_URL}Client/Note/${id}`, config)
        .then(response => {
          swal.fire({
            title: 'Udało się!',
            text: 'Notatka została usunięta',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.FetchNotes()
        })
        .catch(error => {
          swal({
            title: 'Błąd!',
            text: 'Nie udało się usunąć notatki',
            type: 'error',
          })
        })
    },
    sendMessage() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Profile/${this.$route.params.id}`, config)
        .then(response => {
          this.message.number = response.data.success.phone_number
          this.ApiCall()
        })
    },
    ApiCall() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const postData = {
        title: this.message.title,
        content: this.message.content,
        number: this.message.number,
      }

      axios.post(`${process.env.VUE_APP_API_URL}Send-SMS`, postData, config)
        .then(response => {
          swal.fire({
            type: 'success',
            title: 'Message Sent!',
            text: 'Message sent successfully!',
            icon: 'success',
          })

          this.$router.push('/Wiadomosci')
        })
        .catch(error => {
          swal.fire({
            type: 'error',
            title: 'Oops...',
            icon: 'error',
            text: 'Coś poszło nie tak... Skontaktuj się z administratorem!',
          })
        })
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 1200px) {

}

.card {
    margin-right: 50px;
    margin-left: 50px;
}

@media screen and (max-width: 700px) {
    .card {
        margin-right: 5px;
        margin-left: 5px;
    }
}
</style>
